import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font: 400 14px Rubik, sans-serif;
  }

  html, body {
    overscroll-behavior-y: none;
  }

  button {
    padding-bottom: 5px;
    border: none;
    background: none;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    font: 400 14px Rubik, sans-serif;
  }

  input {
    outline: 0;
    border: none;
    background: transparent;
    font: 400 14px Rubik, sans-serif;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  a {
    text-decoration: none;

    &:link {
      color: rgb(254, 80, 28);
    }

    &:visited {
      color: rgb(196, 62, 22);
    }
  }
`;
